
import { defineComponent, PropType } from 'vue';
import TableRow from './TableRow.vue';
import { IIntegration } from '../../entities/integration';
import { IntegrationType } from '@/entities/integrationType';

export default defineComponent({
  components: {
    TableRow,
  },
  computed: {
    isDiscord() {
      return this.integration.type === IntegrationType.Discord;
    },
    isRust() {
      return this.integration.type === IntegrationType.Rust;
    },
    integrationType() {
      if (this.integration.type === IntegrationType.Discord) {
        return 'Discord Guild';
      }

      if (this.integration.type === IntegrationType.Rust) {
        return 'Rust Server';
      }

      return undefined;
    },
  },
  props: {
    integration: {
      type: Object as PropType<IIntegration>,
      required: true,
    },
  },
});
