
import { mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { IIntegration } from '../../../entities/integration';
import { getIntegrations } from '@/services/steamcord/actions';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';
import IntegrationCard from '@/components/dashboard/IntegrationCard.vue';

export default defineComponent({
  async beforeRouteEnter(to, from, next) {
    const { currentOrg } = useOrgStore();
    if (!currentOrg) {
      next({ name: 'Internal Server Error' });
      return;
    }

    const integrations = await getIntegrations(currentOrg.id);

    if (integrations.length === 0) {
      next({ name: 'New Integration', replace: true });
      return;
    }

    next((vm) => {
      (vm as any).setIntegrations(integrations);
    });
  },
  components: {
    IntegrationCard,
  },
  computed: mapWritableState(useMainStore, ['loading']),
  data() {
    return {
      integrations: undefined as Partial<IIntegration>[] | undefined,
    };
  },
  methods: {
    setIntegrations(integrations: Partial<IIntegration>[]) {
      this.integrations = integrations;
    },
  },
  mounted() {
    this.loading = false;
  },
});
