import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "integration-card__name" }
const _hoisted_2 = { class: "no-margin-top high-em" }
const _hoisted_3 = { class: "no-margin-top" }
const _hoisted_4 = { class: "table__cell--clickable" }
const _hoisted_5 = { class: "table__cell-button align-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_table_row = _resolveComponent("table-row")!

  return (_openBlock(), _createBlock(_component_table_row, null, {
    text: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.integration.name), 1),
        _createElementVNode("span", _hoisted_3, [
          _createElementVNode("span", {
            class: _normalizeClass({ 'no-margin-top': true,  'discord': _ctx.isDiscord, 'rust': _ctx.isRust })
          }, _toDisplayString(_ctx.integrationType), 3)
        ])
      ])
    ]),
    buttons: _withCtx(() => [
      _createElementVNode("td", _hoisted_4, [
        _createElementVNode("button", _hoisted_5, [
          _createVNode(_component_fa_icon, {
            class: "high-em text--large",
            icon: ['far', 'eye']
          })
        ])
      ])
    ]),
    _: 1
  }))
}